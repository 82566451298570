import React, { Component } from 'react';
import { store } from '../../App/store';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

class SidebarContent extends Component {
  static contextType = store;

  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  constructor(props){
    super(props);
    this.state = {
      isAdmin: false,
      surveyClient: false,
      sicSurveyUrl: props.sicSurveyUrl
    }
  }

  componentDidMount = async () => {
    const globalState = this.context;
    const { userGroup, surveyClient } = globalState.state;

    this.setState({
      isAdmin: userGroup === 'Admin', 
      surveyClient: surveyClient.toString() === true
    })
  }
  
  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  // showSurveyPopup = () => {
  //   const globalState = this.context;
  //   const { dispatch } = globalState;

  //   // update cookie, update store
  //   let surveyCookieExpiration = new Date(Date.parse('01 Nov 2023 00:00:00 CDT'));
  //   cookies.set('sicSurveyPopup', 'center', {path: '/', secure: true, sameSite: 'strict', expires: surveyCookieExpiration})
  //   dispatch({ type: 'SET_SIC_SURVEY_POPUP', value: 'center' });
  // };

  render() {
    const globalState = this.context;
    const { userGroup, accountID, accountName, surveyClient, customSurveyClient, legacySurveyClient, givingDocsClient, platformClient, fulfillmentClient } = globalState.state;

    const isDev = process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV !== 'prod';
    const isAdmin = isDev && userGroup === 'Admin';
    const demo = accountName === 'Utopia University';

    const surveyRequestAccess = surveyClient.toString() === "true";
    const customSurveyAccess = customSurveyClient.toString() === "true";
    const legacySurveyAccess = legacySurveyClient.toString() === "true";
    const givingDocsAccess = givingDocsClient.toString() === "true";
    const platformAccess = platformClient.toString() === "true";
    const fulfillmentAccess = fulfillmentClient.toString() === "true";
    const givingDocsUser = accountID === 'A0046007';

    //let showSurveyFeedback = (isDev && isAdmin) || (typeof sicSurveyUrl !== 'undefined' && sicSurveyUrl !== '');
    //let showSurveyFeedback = false;

    return (
      <div className="sidebar__content">
        {isDev ? (
            <ul className="sidebar__block sidebar__block-leads">
              <SidebarCategory title="Home" icon="home" >
                <SidebarLink title="Production" route="/app/home" onClick={this.hideSidebar} />
                <SidebarLink title="Home 2.0" route="/app/v2/home" onClick={this.hideSidebar} />
              </SidebarCategory>
            </ul>
          ) : (
            <ul className="sidebar__block">
              <SidebarLink title="Home" icon="home" route="/app/home" />
            </ul>
          )
        }
        {!givingDocsUser && 
        <ul className="sidebar__block sidebar__block-leads">
          {isDev ? (
              <SidebarCategory title="Leads" icon="users" >
                <SidebarLink title="Production" route="/app/leads" onClick={this.hideSidebar} />
                <SidebarLink title="Development" route="/app/leads-dev" onClick={this.hideSidebar} />
                <SidebarLink title="Custom Development" route="/app/leads-dev-custom" onClick={this.hideSidebar} />
              </SidebarCategory>
            ) : (
              <SidebarLink title="Leads" icon="users" route="/app/leads" />
            )
          }
        </ul>
        }

        {!givingDocsUser && 
        <ul className="sidebar__block">
          <SidebarCategory title="Analytics" icon="chart-bars">
            {isDev ? (
                <SidebarCategory title="Web">
                  <SidebarLink title="Dev 2.0" route="/app/v2/web" onClick={this.hideSidebar} />
                  <SidebarLink title="Overview" route="/app/web" onClick={this.hideSidebar} />
                  <SidebarLink title="Audience" route="/app/web-audience" onClick={this.hideSidebar} />
                  <SidebarLink title="Forms" route="/app/web-forms" onClick={this.hideSidebar} />
                </SidebarCategory>
              ) : (
                <SidebarLink title="Web" route="/app/web" onClick={this.hideSidebar} />
              )
            }
            {isDev ? (
              <SidebarCategory title="Email">
                <SidebarLink title="Dev 2.0" route="/app/v2/email" onClick={this.hideSidebar} />
                <SidebarLink title="Production" route="/app/email" onClick={this.hideSidebar} />
                <SidebarLink title="Development" route="/app/email-dev" onClick={this.hideSidebar} />
              </SidebarCategory>
              ) : (
                <SidebarLink title="Email" route="/app/email" onClick={this.hideSidebar} />
              )
            }
            {isDev ? (
                <SidebarCategory title="Survey">
                  <SidebarLink title="Overview" route="/app/survey" onClick={this.hideSidebar} />
                  <SidebarLink title="Requests" route="/app/survey-requests" onClick={this.hideSidebar} />
                  {customSurveyAccess &&
                    <SidebarLink title="Custom Overview" route="/app/survey-custom" onClick={this.hideSidebar} />
                  }
                  {customSurveyAccess &&
                    <SidebarLink title="Custom Requests" route="/app/survey-custom-requests" onClick={this.hideSidebar} />
                  }
                  <SidebarLink title="Survey [DEV]" route="/app/survey-dev" onClick={this.hideSidebar} />
                  <SidebarLink title="Requests [DEV]" route="/app/survey-requests-dev" onClick={this.hideSidebar} />
                  {isDev && legacySurveyAccess &&
                    <SidebarLink title="Legacy Survey" route="/app/legacy-survey" onClick={this.hideSidebar} />
                  }
                </SidebarCategory>
              ) : (surveyRequestAccess || legacySurveyAccess) ?
              (
                <SidebarCategory title="Survey">
                  <SidebarLink title="Overview" route="/app/survey" onClick={this.hideSidebar} />
                  <SidebarLink title="Requests" route="/app/survey-requests" onClick={this.hideSidebar} />
                  {customSurveyAccess &&
                    <SidebarLink title="Custom Overview" route="/app/survey-custom" onClick={this.hideSidebar} />
                  }
                  {customSurveyAccess &&
                    <SidebarLink title="Custom Requests" route="/app/survey-custom-requests" onClick={this.hideSidebar} />
                  }
                  {legacySurveyAccess &&
                    <SidebarLink title="Legacy Survey" route="/app/legacy-survey" onClick={this.hideSidebar} />
                  }
                </SidebarCategory>
              ) : (
                <SidebarLink title="Survey" route="/app/survey" onClick={this.hideSidebar} />
              )
            }
            {fulfillmentAccess &&
              <ul className="sidebar__block sidebar__block-leads">
                <SidebarCategory title="Direct Mail">
                  {/* <SidebarLink title="Results" route="/app/direct-mail/results" onClick={this.hideSidebar} /> */}
                  <SidebarLink title="Response Fulfillment" route="/app/direct-mail/fulfillment" onClick={this.hideSidebar} />
                </SidebarCategory>
              </ul>
            }
            
          </SidebarCategory>
        </ul>
        }
        {/* <ul className="sidebar__block">
          <SidebarCategory title="Projects (TBD)" icon="select">
            <SidebarLink title="Projects" route="/app/projects" onClick={this.hideSidebar} />
            <SidebarLink title="Proofs" route="/app/proofs" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Resources (TBD)" icon="leaf">
            <SidebarLink title="Resources 1" route="/app/overview" onClick={this.hideSidebar} />
            <SidebarLink title="Resources 2" route="/app/web" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul> */}
        {(platformAccess || demo) && !givingDocsUser &&
          <ul className="sidebar__block sidebar__link-wide">
            <SidebarLink title="Performance Platform" icon="rocket" route="/app/performance-platform" />
          </ul>
        }
        {(givingDocsAccess || demo) &&
          <ul className="sidebar__block">
            <NavLink
              to={`/app/giving-docs`}
              onClick={this.hideSidebar}
              activeClassName="sidebar__link-active"
            >
              <li className="sidebar__link">
                <span className={`sidebar__link-icon gd-logo-icon`} style={{fill:"#00928E"}}>
                  <svg version="1.1" height="14" width="14" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground: "new 0 0 64 64"}} >
                    <path d="M63.31,28.13c-0.29,5.04-0.4,10.1-0.93,15.12c-0.64,6.17-0.17,12.26,0.68,18.35
                    c0.25,1.82,0.62,2.17-1.72,1.65c-8.32-1.86-16.84-1.01-25.33-0.53c-5.68,0.33-11.57,0.34-17.1-0.81C9.8,60.03,3.07,54.86,1.01,45.04
                    c-2.05-9.8,5.53-20.24,15.56-21.19c1.76-0.17,3.56-0.13,5.32,0.02c3,0.25,3.12,0.87,2.65-2.56c-0.31-2.23-0.05-4.65,0.45-6.87
                    C26.55,7.57,30.97,3,37.66,1.27C44.7-0.55,51.23,0.9,56.43,6.45c5.07,5.41,6.59,12.1,6.88,19.22
                    C63.34,26.49,63.31,27.31,63.31,28.13z M51.55,59.21c1.32,0.1,3.09,0.18,4.86,0.36c4.05,0.42,3.24,1.01,3.13-3.04
                    c-0.16-5.87-0.07-11.75,0.06-17.62c0.11-5.05,0.72-10.09,0.64-15.13c-0.1-6.42-2.68-12-7.43-16.31c-3.84-3.48-8.55-4.99-13.65-3.55
                    C31.4,6.11,26.82,12.97,27.31,21c0.11,1.84,0.88,5.12,0.31,5.61c-0.48,0.41-2.4,0.45-7.37,0.11c-6.32-0.43-10.96,2.31-14.07,7.35
                    c-3.31,5.37-3.64,11.04,0.38,16.49c2.16,2.93,4.72,5.27,8.07,6.74c4.32,1.9,8.84,2.82,13.55,2.68
                    C35.82,59.76,47.37,58.97,51.55,59.21z"/>
                    <rect x="34.15" y="42.52" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -18.1209 44.9904)" width="22.2" height="3.7"/>
                    <rect x="28.16" y="36.53" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -15.6411 39.0035)" width="22.2" height="3.7"/>
                    <rect x="22.1" y="30.47" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.129 32.9389)" width="22.2" height="3.7"/>
                  </svg>
                </span>
                <p className="sidebar__link-title">Giving Docs</p>
              </li>
            </NavLink>
          </ul>
        }
        <ul className="sidebar__block">
          <SidebarCategory title="Support" icon="bubble">
            <SidebarLink title="Support Request" route="/app/support/request" onClick={this.hideSidebar} />
            <SidebarLink title="How-to Videos" route="/app/support/videos" onClick={this.hideSidebar} />
            <SidebarLink title="Release Notes" route="/app/support/release-notes" onClick={this.hideSidebar} />
            <SidebarLink title="Glossary" route="/app/support/glossary" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul>
        {/* {showSurveyFeedback &&
          <ul className="sidebar__block">
            <li className="sidebar__link" onClick={this.showSurveyPopup}>
              <span className={`sidebar__link-icon lnr lnr-star`} />
              <p className="sidebar__link-title">Feedback</p>
            </li>
          </ul>
        } */}
        <ul className="sidebar__block">
          <SidebarLink title="Log Out" icon="exit" route="/logout" />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
